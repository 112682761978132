import type { ToRefs } from 'vue'
import type { FormKitNode } from '@formkit/core'
import type {
  LeaseVariationKindWithField,
  ILeaseVariation,
  ILeaseVariationDocument,
  ICreateLeaseVariationRequest,
} from '@register'
import { LeaseVariationKind } from '@register'
import type { ICreateLeaseVariationForm } from '../types'

export const useCreateAmendmentForm = (
  props: ToRefs<{
    amendment: ILeaseVariationDocument
  }>,
) => {
  // PROPS
  const { amendment } = props

  // GLOBAL
  const router = useRouter()
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  // FORM
  const form = ref<{ node: FormKitNode }>()
  const formModelValue = ref<ICreateLeaseVariationForm>(
    {} as ICreateLeaseVariationForm,
  )

  // AVAILABLE FIELDS

  const { data: availableFields } = useApiLeaseAvailableFields(
    divisionId,
    leaseId,
  )

  const fieldsOptions = computed(() => {
    if (!formModelValue.value.kind) return undefined
    return getAvailableFieldsByKind(formModelValue.value.kind)
  })

  // SUBMIT
  const { mutateAsync: saveVariation } = useApiLeaseVariationsMutation(
    divisionId,
    leaseId,
  )
  const onSubmit = useSubmitHandler<
    ICreateLeaseVariationForm,
    ILeaseVariation[]
  >(
    (data) =>
      saveVariation({ body: createPayload(data) }) as Promise<
        ILeaseVariation[]
      >,
    {
      onSuccess: (response) => {
        router.push(
          `/division/${divisionId}/lease/${leaseId}/amendments/${response[0].id}`,
        )
      },
    },
  )

  return {
    form,
    formModelValue,
    fieldsOptions,
    onSubmit,
  }

  function createPayload(
    data: ICreateLeaseVariationForm,
  ): ICreateLeaseVariationRequest {
    const isRemove = formModelValue.value.kind?.includes('REMOVED') ?? false

    return {
      documentId: amendment.value.document?.id,
      leaseFieldId: data.leaseFieldId,
      type: data.type,
      kind: isRemove ? LeaseVariationKind.REMOVED : undefined,
      startDate: data.startDate,
    }
  }

  function getAvailableFieldsByKind(type: LeaseVariationKindWithField) {
    const [kind, fieldType] = getVariationKindAndFieldTypeByKindWithField(type)

    const res = availableFields.value?.filter((field) => {
      let _kind: boolean | undefined

      if (
        kind === LeaseVariationKind.ADDED ||
        kind === LeaseVariationKind.CHANGED
      ) {
        _kind =
          field.kind?.includes(LeaseVariationKind.ADDED) ||
          field.kind?.includes(LeaseVariationKind.CHANGED)
      } else {
        _kind = field.kind?.includes(kind)
      }

      return field.fieldType === fieldType && _kind
    })

    return res ?? []
  }
}
