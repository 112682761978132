<script setup lang="ts">
import { useCreateAmendmentForm } from './composables'
import {
  LeaseVariationType,
  getDocumentTypeFromVariationType,
  leaseVariationKindWithFieldOptions,
  leaseVariationTypeOptions,
  type ILeaseVariationDocument,
} from '@register'
import type { ICreateLeaseVariationForm } from './types'
import { twMerge } from 'tailwind-merge'

interface Props {
  amendment: ILeaseVariationDocument
  startDate?: string
}
// PROPS
const props = withDefaults(defineProps<Props>(), {
  startDate: undefined,
})
/**
 * RULES:
 * When adding an amendment to a document, check if this document has any field
 *   with type of EXTENSION, TRANSFER, SURRENDER or OPTION_EXERCISE AND IF there
 *   are any of these, hide the field type
 *
 * If there are any field and start date and a document, hide the start date
 *
 * For amendments without documents, never hide the type and start date
 */

// EMITS
const emit = defineEmits<{
  (event: 'close'): void
  (event: 'confirm', formValue: ICreateLeaseVariationForm): void
}>()

// FORM
const { form, formModelValue, fieldsOptions, onSubmit } =
  useCreateAmendmentForm(toRefs(props))
const modalClass = computed(() => {
  return twMerge(
    'bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800',
    props.amendment.document
      ? 'lg:max-w-[1200px] lg:min-w-[1000px] '
      : 'max-w-[650px]',
  )
})

// FETCH AMENDMENTS
const divisionId = getCurrentDivisionId()
const preSelectedField = ref()

const documentURI = computed(() => {
  const id = props.amendment.document?.id
  if (!id) return
  const apiURL = useRuntimeConfig().public.apiURL
  return `${apiURL}/companies/${divisionId}/documents/${id}/download`
})

const canNotChangeVariationType = computed(() => {
  if (!props.amendment?.document) return false
  const found = props.amendment?.variations?.some((variation) => {
    if (variation.type)
      return [
        LeaseVariationType.EXTENSION,
        LeaseVariationType.TRANSFER,
        LeaseVariationType.SURRENDER,
        LeaseVariationType.OPTION_EXERCISE,
      ].includes(variation.type)
  })

  return found
})

const preSelectedType = computed(() => {
  // If there is document or start date, and we cannot change the type, then it must be type of VARIATION
  if (props.startDate || canNotChangeVariationType.value) {
    return LeaseVariationType.VARIATION
  }

  return undefined
  // If not, get the type from the document, if exists
  const type = props.amendment.document?.type
  return getDocumentTypeFromVariationType(type)
})

// If there is start date, set type to VARIATION
if (props.startDate) {
  formModelValue.value.type = LeaseVariationType.VARIATION
  formModelValue.value.startDate = props.startDate
}
</script>

<template>
  <ModalBase
    title="Add an amendment"
    :modal-class="modalClass.toString()"
    @confirm="form?.node.submit()"
    @close="emit('close')"
  >
    <div
      class="flex flex-col-reverse gap-4 lg:grid"
      :class="{
        'lg:grid-cols-2': amendment.document,
      }"
    >
      <div
        v-if="amendment.document"
        class="border-gray-700 lg:-mb-20 lg:-ml-6 lg:-mt-6 lg:border-r"
      >
        <LazyWebViewer
          :initial-doc="documentURI"
          :options="{ enableAnnotations: false }"
          class="h-[calc(50vh)] min-h-[400px] w-full lg:h-[calc(100vh-150px)]"
        />
      </div>
      <FormKit
        ref="form"
        v-model="formModelValue"
        type="form"
        form-class="space-y-4 w-full"
        :actions="false"
        @submit="onSubmit"
      >
        <FormKit
          name="type"
          :type="canNotChangeVariationType ? 'hidden' : 'listbox'"
          label="Type of variation"
          :options="leaseVariationTypeOptions"
          :searchable="true"
          dirty-behavior="compare"
          validation="required"
          :value="preSelectedType"
        />

        <!-- Variation Kind -->
        <FormKit
          v-if="formModelValue?.type === 'variation'"
          type="listbox"
          name="kind"
          label="Action"
          :options="leaseVariationKindWithFieldOptions"
          :searchable="true"
          dirty-behavior="compare"
          validation="required"
        />

        <!-- Variation Field -->
        <FormKit
          v-if="formModelValue.type === 'variation' && fieldsOptions"
          :key="`leaseField_${formModelValue.kind}`"
          type="listbox"
          name="leaseFieldId"
          :searchable="true"
          label="Field"
          :options="fieldsOptions"
          label-by="name"
          track-by="id"
          dirty-behavior="compare"
          help="Select which field you want to vary"
          validation="required"
        />

        <!-- Effective date -->
        <FormKit
          :type="startDate ? 'hidden' : 'date'"
          name="startDate"
          label="Effective date"
          min="1900-01-01"
          max="2100-12-31"
          validation="required"
          dirty-behavior="compare"
          help="The date when the change will be effective"
          :value="
            startDate ??
            amendment.variations?.[0].startDate ??
            amendment?.document?.createdAt
          "
        />

        <FormKit name="isApproved" type="hidden" />
      </FormKit>
    </div>
  </ModalBase>
</template>
