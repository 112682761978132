import { useQuery } from '@tanstack/vue-query'
import type { ILeaseVariationAvailableField } from '@register'

export const useApiLeaseAvailableFields = (
  divisionId: string,
  leaseId: string,
) => {
  return useQuery({
    queryKey: [
      'companies',
      divisionId,
      'leases',
      leaseId,
      'documents',
      'variations',
      'availableFields',
    ],
    queryFn: ({ signal }) =>
      fetchAPI<ILeaseVariationAvailableField[]>(
        `/companies/${divisionId}/leaseRegister/leases/${leaseId}/variations/availableFields`,
        {
          signal,
        },
      ),
  })
}
