import {
  LeaseVariationKindWithField,
  LeaseVariationKind,
  LeaseFieldType,
} from '@register'
import { exhaustiveMatchingGuard } from '~/utils/exhaustiveMatchingGuard'

export const getVariationKindAndFieldTypeByKindWithField = (
  type: LeaseVariationKindWithField,
): [LeaseVariationKind, LeaseFieldType] => {
  switch (type) {
    case LeaseVariationKindWithField.ADD_CLAUSE: {
      return [LeaseVariationKind.ADDED, LeaseFieldType.CLAUSE]
    }
    case LeaseVariationKindWithField.ADD_DEFINITION: {
      return [LeaseVariationKind.ADDED, LeaseFieldType.DEFINITION]
    }
    case LeaseVariationKindWithField.ADD_VARIATION: {
      return [LeaseVariationKind.ADDED, LeaseFieldType.FIELD]
    }
    case LeaseVariationKindWithField.CHANGE_CLAUSE: {
      return [LeaseVariationKind.CHANGED, LeaseFieldType.CLAUSE]
    }
    case LeaseVariationKindWithField.CHANGE_DEFINITION: {
      return [LeaseVariationKind.CHANGED, LeaseFieldType.DEFINITION]
    }
    case LeaseVariationKindWithField.CHANGE_VARIATION: {
      return [LeaseVariationKind.CHANGED, LeaseFieldType.FIELD]
    }

    case LeaseVariationKindWithField.REMOVE_CLAUSE: {
      return [LeaseVariationKind.REMOVED, LeaseFieldType.CLAUSE]
    }
    case LeaseVariationKindWithField.REMOVE_DEFINITION: {
      return [LeaseVariationKind.REMOVED, LeaseFieldType.DEFINITION]
    }
    default: {
      return exhaustiveMatchingGuard(type)
    }
  }
}
